import React from 'react';
import Layout from '../components/Layout';
import {Container, Grid, Link, makeStyles} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import FaxIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import {graphql, useStaticQuery} from "gatsby";


const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

export default function App() {

    const classes = useStyles();
    const data = useStaticQuery(graphql`
    {
      heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <Layout heroHeadline1="Impressum"
                heroImage={data.heroImage.img.fluid}
        >
            <Container className={classes.cardGrid}>
                <Grid
                    spacing={10}// Add it here :)
                    container
                >
                    <Grid item xs={12}>
                        <p>
                            Nach § 5 Telemediengesetz (TMG) sind wir verpflichtet, auf dieser Internetseite folgende
                            Informationen über unsere Kanzlei zu veröffentlichen:
                        </p>
                        <p>
                            Dreyer & Pfeiffer<br/>
                            Rechtsanwälte<br/>
                            Ridlerstraße 33<br/>
                            80339 München<br/>
                            <br/>
                            <EmailIcon fontSize="small"/>&nbsp;
                            <Link href="mailto:kanzlei@dreyer-pfeiffer.de">
                                kanzlei@dreyer-pfeiffer.de
                            </Link>
                            <br/>

                            <PhoneIcon fontSize="small"/>&nbsp;
                            <Link href="tel:+498924206960">
                                089-242069-60
                            </Link>
                            <br/>

                            <FaxIcon fontSize="small"/>&nbsp;
                            <Link href="tel:+498924206969">
                                089-242069-69
                            </Link>
                            <br/>
                        </p>
                        <p>
                            Zuständige Aufsichtsbehörde:<br/>
                            Rechtsanwaltskammer für den OLG Bezirk München
                        </p>
                        <p>
                            Umsatzsteuer-Identifikationsnummer:<br/>
                            DE 812535650
                        </p>
                        <p>
                            Die gesetzliche Bezeichnung der einzelnen Berufsträger ist "Rechtsanwalt/Rechtsanwältin"
                            (RA/RAin).
                            Die Berufsbezeichnung wurde jeweils in der Bundesrepublik Deutschland verliehen.
                        </p>
                        <p>
                            Maßgebliche berufsrechtliche Regelungen:
                            <ul>
                                <li>Bundesrechtsanwaltsordnung (BRAO)</li>
                                <li>Berufsordnung für Rechtsanwälte (BORA)</li>
                                <li>Fachanwaltsordnung (FAO)</li>
                                <li>Rechtsanwaltsvergütungsgesetz (RVG)</li>
                                <li>Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft</li>
                            </ul>
                        </p>
                        <p>
                            Die jeweils aktuellen Texte können auf der Homepage der Bundesrechtsanwaltskammer <Link
                            href="http://www.brak.de/">(www.brak.de)</Link> eingesehen werden.
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}
